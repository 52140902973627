import * as React from "react";
import { Device } from "../../design-tokens/media-queries";
import { grid } from "../../design-tokens/grid";
import { Fonts } from "../../design-tokens/fonts";

interface SectionHeadingProps {
  subHeading?: string;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
  subHeading,
  children,
}) => {
  return (
    <div
      css={{
        marginBottom: grid(3),
      }}
    >
      <h2
        css={{
          fontSize: "22px",
          lineHeight: "28px",
          fontWeight: Fonts.Weight.Bold,
          marginBottom: subHeading ? grid(1) : 0,

          [Device.DesktopTablet]: {
            fontSize: "30px",
            lineHeight: "36px",
          },
        }}
      >
        {children}
      </h2>
      {subHeading && <div>{subHeading}</div>}
    </div>
  );
};

export default SectionHeading;
