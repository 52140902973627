import * as React from "react";
import { Link } from "gatsby";
import Section from "../../../ui-library/section/section";

import {
  getCOVIDIntentUrl,
  getCountryUrl,
} from "../../../../utils/question-url";
import { SPECIAL_INTENTS } from "../../../../constants/special-intents";
import Image from "../../../ui-library/image/image";
import Row from "../../../ui-library/column-system/row";
import Column from "../../../ui-library/column-system/column";
import SectionHeading from "../../../ui-library/section/section-heading";
import styles from "./countries-situation-ctas.styles";
import GlobalIcon from "./assets/globe-icon.svg";
import USFlag from "./assets/us-round-flag.svg";
import CAFlag from "./assets/ca-round-flag.svg";
import AUFlag from "./assets/au-round-flag.svg";

const CTAS = [
  {
    image: GlobalIcon,
    ctaText: "Global situation",
    url: getCOVIDIntentUrl(SPECIAL_INTENTS.CountrySituationList.alias),
  },
  {
    image: USFlag,
    ctaText: "USA situation",
    url: getCountryUrl("united-states"),
  },
  {
    image: CAFlag,
    ctaText: "Canada situation",
    url: getCountryUrl("canada"),
  },
  {
    image: AUFlag,
    ctaText: "Australia situation",
    url: getCountryUrl("australia"),
  },
];

const CountriesSituationCTAs: React.FC = () => {
  return (
    <Section>
      <SectionHeading subHeading="Find out the situation reports for all countries and dive deeper for a few others">
        How is the current situation around the world?
      </SectionHeading>

      <Row isInline={true}>
        {CTAS.map((cta) => (
          <Column
            key={cta.ctaText}
            spanLg={3}
            spanMd={3}
            spanSm={3}
            isInline={true}
          >
            <Link to={cta.url} css={styles.root}>
              <div css={styles.image}>
                <Image src={cta.image} alt={cta.ctaText} />
              </div>
              <div css={styles.content}>
                <div css={styles.ctaText}>{cta.ctaText}</div>
              </div>
            </Link>
          </Column>
        ))}
      </Row>
    </Section>
  );
};

export default CountriesSituationCTAs;
